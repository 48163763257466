import styled from "vue-styled-components";
import { defaultTheme } from "../../../../shared/theme";
import { themeResolver } from "../../../../utils";

const themeColor = defaultTheme.themes[themeResolver()];

export const Title = styled.h2`
  color: ${themeColor.primary};
  font-weight: 400;
  font-size: 1.8rem;
`;

export const TicketsWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
`;

export const TicketItem = styled.div`
  width: 430px;
`;

export const NoData = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-items: center;
  text-align: center;
  padding-top: 10%;
`;
