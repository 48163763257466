<script>
import Helpers from "@/mixins";
export default {
  mixins: [Helpers],
  props: ["title", "value"],
  mounted() {
    this.Helpers;
  },
};
</script>
