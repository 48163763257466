<script>
import Helpers from "@/mixins";
export default {
  data: () => ({
    showModalDetails: false,
  }),
  props: ["venda"],
  mixins: [Helpers],
  mounted() {
    this.Helpers;
  },
  methods: {
    removerIndex(index) {
      this.$emit("removeIndex", index);
    },
    showModal() {
      this.showModalDetails = !this.showModalDetails;
    },
  },
};
</script>
