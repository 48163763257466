import styled from "vue-styled-components";
import { defaultTheme } from "../../../../shared/theme";
import { themeResolver } from "../../../../utils";

const themeColor = defaultTheme.themes[themeResolver()];

export const Container = styled.div`
  text-align: center;
  padding: 12px;
  display: flex;
  justify-content: space-between;
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
`;

export const TripDetailsContainer = styled.div`
  padding: 10px;
  display: flex;
  align-items: center;
  border-right: 2px solid #ccc;
`;

export const TripDetailsContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  text-align: center;
  width: 33%; ;
`;

export const Title = styled.h4`
  font-size: 1rem;
  padding: 10px;
  color: ${themeColor.text};
`;

export const TimeLabel = styled.div`
  color: ${themeColor.primary};
  font-size: 1.4rem;
`;

export const TripMiddleContent = styled.div`
  display: flex;
  justify-content: space-between;
  text-align: center;
`;

export const DetailsIconsContainer = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
`;

export const DetailsLine = styled.span`
  border-bottom: 2px;
  border-bottom-color: #bbbbbb;
  border-bottom-style: solid;
  width: 80%;
`;

export const Line = styled.span`
  border-bottom: 2px;
  border-bottom-color: #bbbbbb;
  border-bottom-style: solid;
  width: 1%;
  height: 100%;
`;

export const TimeTravelLabelContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TimeTravelLabel = styled.div`
  color: ${themeColor.secondary};
`;

export const DirectionTravelLabel = styled.h3`
  text-transform: lowercase;
`;

export const DirectionContainer = styled.div`
  color: ${themeColor.primary};
  display: flex;
  align-items: center;
  justify-content: center;
  h3::first-letter {
    text-transform: uppercase;
  }
`;

export const LineContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-right: 2px solid #ccc;
  padding: 0 10px;
`;

export const LineLabel = styled.h3`
  color: ${themeColor.text};
`;

export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

export const ActionsItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
`;

export const ActionsItemText = styled.div`
  color: ${themeColor.primary};
  text-align: center;
  font-size: 1rem;
`;

export const ActionsSubContainer = styled.div`
  display: flex;
  padding: 10px;
`;
