<script>
import TravelCard from "../TravelCard";
import { Title, TicketsWrapper, TicketItem, NoData } from "./styled";
import { Swiper, SwiperSlide } from "swiper-vue2";
import "swiper/swiper-bundle.css";

export default {
  props: ["tickets", "selectedTravels"],
  components: {
    Title,
    TravelCard,
    TicketsWrapper,
    TicketItem,
    NoData,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      ticketsIda: [],
      ticketsVolta: [],
    };
  },
  watch: {
    tickets: function (newTickets) {
      this.ticketsIda = [];
      this.ticketsVolta = [];
      if (newTickets.length) {
        newTickets.forEach((item, index) => {
          item.index = index;
          if (item.sentido === "IDA") {
            this.ticketsIda.push(item);
          } else {
            this.ticketsVolta.push(item);
          }
        });
      }
    },
  },
  methods: {
    selectedTravel(ticket, i) {
      this.$emit("selectedTravel", ticket, i);
    },
  },
};
</script>
