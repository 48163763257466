import styled from "vue-styled-components";
import { defaultTheme } from "../../shared/theme";
import { themeResolver } from "../../utils";

const themeColor = defaultTheme.themes[themeResolver()];

export const PDVContent = styled.div`
  .stepper {
    margin-top: 0px;
    position: relative;
    top: -50px;

    @media screen and (min-width: 1860px) {
     width: 80%;
     margin: 0 auto;
  }

  }
  .v-stepper__wrapper {
    width: 100%;
  }
`;

export const PDVSubtitle = styled.h2`
  color: ${themeColor.primary};
  font-weight: 400;
  margin-bottom: 20px;
`;

export const PDVValue = styled.h2`
  color: ${themeColor.warning};
  font-weight: 600;
  margin-right: 20px;
`;

export const TicketsWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
`;

export const TicketItem = styled.div`
  width: 33%;

  @media screen and (min-width: 1860px) {
      width: 25%;
  }
`;


