<template>
  <BusBackContainer>
    <v-img src="@/assets/busback1.png" />
    <v-img src="@/assets/busback2.png" />
  </BusBackContainer>
</template>

<script>
import { BusBackContainer } from "./styled";
export default {
  components: {
    BusBackContainer,
  },
};
</script>

<style>
</style>
