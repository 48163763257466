import styled from "vue-styled-components";

export const BusContainer = styled.div`
  display: flex;
  margin: 20px;
`;

export const BusContent = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  max-height: 221px;
  border-bottom: 2px solid #aaa;
  border-top: 2px solid #aaa;
  position: relative;
  justify-content: space-around;
  min-width: 600px;
`;

export const BusSeatsContainer = styled.span``;

export const BusSeatContentTopRow = styled.div`
  display: flex;
  flex-direction: column;
`;

export const BusSeatContentBottomRow = styled.div`
  display: flex;
  flex-direction: column;
`;

export const BusArrowIconButton = styled.div`
  border-bottom: 2px solid #aaa;
  border-top: 2px solid #aaa;
  max-height: 221px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;
