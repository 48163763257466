<script>
import Helpers from "@/mixins";
export default {
  mixins:[Helpers],
  props:["data"],
  mounted(){
    this.Helpers;
  }
}
</script>
