<script>
import Helpers from "@/mixins";
import CardTravelResume from "@/components/CardTravelResume";
import SingleTravelDetails from "./components/SingleTravelDetails";
import ConectionTravelDetails from "./components/ConectionTravelDetails";
import CardSingleTravel from "./components/CardSingleTravel";
import CardConnectionTravel from "./components/CardConnectionTravel";
import ItemResume from "./components/ItemResume";
import Tickets from "./components/Tickets";
import TicketsList from "./components/TicketCard";
import Search from "./components/Search";
import MessageCashboxOpenToday from "./financeiro/caixa/components/MessageCashboxNoOpenToday";
import { PDVContent, PDVSubtitle, PDVValue } from "./styled";
import TravelCard from "./components/TravelCard";
import Bus from "./components/Bus";
import { ticketsData } from "../../models";
import { Swiper, SwiperSlide } from "swiper-vue2";
import "swiper/swiper-bundle.css";
import axios from "axios";
import QrcodeVue from "qrcode.vue";
import { api2UrlByEnviroment } from "../../utils";

export default {
  mixins: [Helpers],
  components: {
    Search,
    Tickets,
    TicketsList,
    TravelCard,
    PDVContent,
    PDVSubtitle,
    PDVValue,
    CardTravelResume,
    SingleTravelDetails,
    ConectionTravelDetails,
    CardSingleTravel,
    CardConnectionTravel,
    ItemResume,
    MessageCashboxOpenToday,
    Bus,
    Swiper,
    SwiperSlide,
    QrcodeVue,
  },
  props: ["id"],
  data() {
    return {
      fPgto: false,
      formaPagamento: "1",
      valorPago: "0",
      valorFinal: 0,
      mostrarTotal: false,
      sentido: "IDA",
      naoVinculadas: false,
      tabs: "#tab-1",
      menuCalendarioInicial: false,
      menuCalendarioFinal: false,
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      currentDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      dateFormatted: this.formatDate(
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10)
      ),
      id_viagem: 0,
      sendBPe: false,
      pdvCliente: [],
      stepperIndex: 1,
      formasdePagamento: [],
      onibus: [],
      viagem: [],
      linhas: [],
      agencia: 0,
      empresa: 0,
      passagens: [],
      cliente: "",
      clienteSelecionado: null,
      tiposServicos: [],
      selectedTrips: [],
      vendas: [],
      trips: [],
      filter: 0,
      trechos: [],
      trechosDestino: [],
      valorTotal: 0.0,
      tipoVenda: 0,
      tiposVenda: [],
      message: {
        snacshow: false,
        text: "",
        type: "",
        mode: "",
        timeout: 4000,
      },
      dados: {
        data_inicial: new Date(
          Date.now() - new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .substr(0, 10),
        data_final: null,
      },
      valid: true,
      fieldRules: [(v) => !!v || "Este campo é requerido"],
      tipoVendaValor: "",
      passagensIda: [],
      pixId: null,
      dialogPix: false,
      brcode: null,
      pixAwaitingPayment: false,
    };
  },
  created() {
    this.onCreated();
  },
  mounted() {
    this.onMounted();
  },
  computed: {
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
  },
  watch: {
    date() {
      this.dateFormatted = this.formatDate(this.date);
    },
    "$store.state.agency": {
      handler() {
        this.agencia = this.$store.getters.getAgency;
      },
    },
    stepperIndex: function (newStepper) {
      if (this.selectedTrips.length > 1 && newStepper === 3) {
        this.passagensIda = JSON.parse(JSON.stringify(this.passagens));
      }
    },
  },
  methods: {
    onCreated() {
      this.loadTipos();
      this.formasPagamento();
      if (!this.$store.getters.getCurrentCashboxState) {
        this.$router.replace(`/pdv/financeiro/caixa-bilheteiro`);
      }
    },
    onMounted() {
      this.Helpers;
      this.loadAgency();
      this.loadLinhas();
      this.validTrechos();
      if (this.id) {
        this.$store.dispatch("activeLoader", true);
        this.$http
          .get(`/operadores/${this.id}`)
          .then((r) => {
            this.dados = r.data[0];
            this.$store.dispatch("activeLoader", false);
          })
          .catch((e) => {
            this.$store.dispatch("activeLoader", false);
            this.message.text = e;
            this.message.snacshow = true;
            this.message.type = "success";
          });
      }
    },
    initializeTypeSales(i) {
      for (let index = 0; index < this.tiposVenda.length; index++) {
        if (
          this.tiposVenda[index].tipovenda.descricao == "TARIFA NORMAL" ||
          this.tiposVenda[index].tipovenda.descricao == "PROMOCIONAL"
        ) {
          this.passagens[i].tipoVenda = this.tiposVenda[index].tipovenda.id;
        }
      }
    },
    imprimir() {
      // var ficha = document.getElementById("bilhete");
      // var ventimp = window.open(" ", "popimpr");
      // ventimp.document.write(ficha.innerHTML);
      // ventimp.document.close();
      // ventimp.print();
      // ventimp.close();
      this.$http.post(`/bpe`, this.passagens).then((r) => {
        alert(r.data.result);
        this.$store.dispatch("activeLoader", false);
      });
    },
    removeIndice(index) {
      this.vendas.splice(index, 1);
      this.passagens.splice(index, 1);
      this.calcularTotal();
      this.passagensNaoVinculadas();
    },
    // METODO PARA ATUALIZAR A PAGINA PARA RETORNAR PARA O INICIO **********************via****************************************************************************************
    // CALCULAR TOTAL DA VENDA DE ACORDO COM OS VALORES DE CADA BILHETE SELECIONADO ****************************************************************************************
    calcularTotal() {
      this.valorTotal = 0;
      this.valorFinal = 0;
      for (let index = 0; index < this.passagens.length; index++) {
        this.valorTotal +=
          parseFloat(this.passagens[index].valor) +
          parseFloat(
            !this.passagens[index].viagem.conexao
              ? this.passagens[index].venda.taxaembarque
              : this.passagens[index].vendaTrecho1.taxaembarque
          );
      }
      this.valorFinal = parseFloat(this.valorTotal);
      this.$store.dispatch("activeLoader", false);
      this.loadVendas();
    },
    // FIM DO METODO CALCULAR TOTAL DA VENDA DE ACORDO COM OS VALORES DE CADA BILHETE SELECIONADO ****************************************************************************************
    // METODO PARA SELECIONAR O VALOR DA PASSAGEM E APLICAR O DESCONTO DE ACORDO COM O TIPO DE VENDA SELECIONADA *******************
    selectType(uid) {
      const indice = this.passagens.findIndex((passagem) => passagem.uid === uid);
      this.$store.dispatch("activeLoader", true);
      this.$http
        .get(`/pdv/tipovendatext/${this.passagens[indice].tipoVenda}`)
        .then((res) => {
          this.tipoVendaValor = res.data[0].descricao;

          if (!this.passagens[indice].viagem.conexao) {
            if (this.tipoVendaValor === "IDOSO 50%") {
              this.passagens[indice].valor =
                this.passagens[indice].viagem.tarifa.valor / 2;
            } else if (this.tipoVendaValor === "ID JOVEM 50%") {
              this.passagens[indice].valor =
                this.passagens[indice].viagem.tarifa.valor / 2;
            } else if (this.tipoVendaValor === "CORTESIA") {
              this.passagens[indice].valor = 0;
            } else if (this.tipoVendaValor === "DEFICIENTE 100%") {
              this.passagens[indice].valor = 0;
            } else if (this.tipoVendaValor === "PROMOCIONAL") {
              const tipo = this.tiposVenda.find((item) => item.tipovenda.descricao === "PROMOCIONAL");
              this.passagens[indice].valor =
                this.passagens[indice].viagem.tarifa.valor -
                tipo.desconto[0].valor;
            } else if (this.tipoVendaValor === "CASADINHA") {
              const tipo = this.tiposVenda.find((item) => item.tipovenda.descricao === "CASADINHA");
              this.passagens[indice].valor =
                this.passagens[indice].viagem.tarifa.valor -
                tipo.desconto[0].valor;
            } else {
              this.passagens[indice].valor =
                this.passagens[indice].viagem.tarifa.valor;
            }
          } else {
            this.passagens[indice].viagem.vltotal =
              this.passagens[indice].viagem.vltotalCDesconto > 0
                ? this.passagens[indice].viagem.vltotalCDesconto
                : this.passagens[indice].viagem.vltotal;

            if (this.tipoVendaValor == "IDOSO 50%") {
              this.passagens[indice].valor =
                this.passagens[indice].viagem.vltotal / 2;
            } else if (this.tipoVendaValor == "ID JOVEM 50%") {
              this.passagens[indice].valor =
                this.passagens[indice].viagem.vltotal / 2;
            } else if (this.tipoVendaValor == "CORTESIA") {
              this.passagens[indice].valor = 0;
            } else if (this.tipoVendaValor === "DEFICIENTE 100%") {
              this.passagens[indice].valor = 0;
            } else if (this.tipoVendaValor == "PROMOCIONAL") {
              const tipo = this.tiposVenda.find((item) => item.tipovenda.descricao === "PROMOCIONAL");
              this.passagens[indice].valor =
                this.passagens[indice].viagem.vltotal -
                tipo.desconto[0].valor;
            } else if (this.tipoVendaValor == "CASADINHA") {
              const tipo = this.tiposVenda.find((item) => item.tipovenda.descricao === "CASADINHA");
              this.passagens[indice].valor =
                this.passagens[indice].viagem.vltotal -
                tipo.desconto[0].valor;
            } else {
              this.passagens[indice].valor =
                this.passagens[indice].viagem.vltotalCDesconto > 0
                  ? this.passagens[indice].viagem.vltotalCDesconto
                  : this.passagens[indice].viagem.vltotal;
            }
          }
          this.calcularTotal();
        })
        .catch((err) => {
          this.message.text = err;
          this.message.snacshow = true;
          this.message.type = "success";
        })
        .finally(() => this.$store.dispatch("activeLoader", false));
    },
    // INICIO METODO PARA SELECIONAR O  TEXTO DO TIPO DE VENDA SELECIONADO PARA COMPARAR NO METODO ACIMA PARA DESENVOLVER DESCONTO OU TARIFA *************************************************************************************************************************************
    loadTipoVendaText(id) {
      this.$http
        .get(`/pdv/tipovendatext/${id}`)
        .then((r) => (this.tipoVendaValor = r.data[0].descricao))
        .catch((e) => {
          this.$store.dispatch("activeLoader", false);
          this.message.text = e;
          this.message.snacshow = true;
          this.message.type = "success";
        });
    },
    // FIM DO METODO PARA SELECIONAR O  TEXTO DO TIPO DE VENDA SELECIONADO PARA COMPARAR NO METODO ACIMA PARA DESENVOLVER DESCONTO OU TARIFA ***************************************************************************************************************************************
    // METODO PARA SELECIONAR O VALOR DA PASSAGEM E APLICAR O DESCONTO DE ACORDO COM O TIPO DE VENDA SELECIONADA ****************************************************************************************************************************************
    validTrechos() {
      setTimeout(() => {
        this.$http
          .get(`/pdv/localidades/${this.agencia.id_localidade}`)
          .then((r) => {
            this.trechosDestino = r.data;
            this.$store.dispatch("activeLoader", false);
          })
          .catch((e) => {
            this.$store.dispatch("activeLoader", false);
            this.message.text = e;
            this.message.snacshow = true;
            this.message.type = "errors";
          });
      }, 500);
    },
    // METODO PARA SELECIONAR O VALOR DA PASSAGEM E APLICAR O DESCONTO DE ACORDO COM O TIPO DE VENDA SELECIONADA ****************************************************************************************************************************************
    // METODO PARA SELECIONAR O CLIENTE NA PASSAGEM ****************************************************************************************************************************************
    async selectClient(id) {
      await this.$http
        .get(`/clientes/${id}`)
        .then((r) => {
          this.clienteSelecionado = r.data[0];
          this.loadVendas();
          this.$store.dispatch("activeLoader", false);
        })
        .catch((e) => {
          this.$store.dispatch("activeLoader", false);
          this.message.text = e;
          this.message.snacshow = true;
          this.message.type = "error";
        });
    },
    // FINAL DO METODO PARA SELECIONAR O CLIENTE NA PASSAGEM ****************************************************************************************************************************************
    loadSales() {
      this.vendas = [];
      for (let index = 0; index < this.passagens.length; index++) {
        this.vendas.push(this.passagens[index]);
      }
    },
    // MÉTODO PARA VALIDAR SE A PASSAGEM ESTA COM TIPO DE VENDA E CLIENTE SELECIONADO ****************************************************************************************************************************************
    validarPassagens() {
      // for (let index = 0; index < this.passagens.length; index++) {}
    },
    // MÉTODO PARA VALIDAR SE A PASSAGEM ESTA COM TIPO DE VENDA E CLIENTE SELECIONADO ****************************************************************************************************************************************
    loadTipovendaPDV(desc) {
      this.$http
        .post(`pdv/tipovenda`, { desc })
        .then((r) => {
          this.tipoVenda = r.data[0].id;
          this.$store.dispatch("activeLoader", false);
        })
        .catch((e) => {
          this.$store.dispatch("activeLoader", false);
          this.message.text = e;
          this.message.snacshow = true;
          this.message.type = "success";
        });
    },
    async loadTiposVenda(dados) {
      this.$store.dispatch("activeLoader", true);
      this.$http
        .post(`/pdv/tipovendas`, dados)
        .then((r) => {
          this.tiposVenda = r.data.filter(
            (value) => value.tipovenda.id !== "9" && value.tipovenda.id !== "6"

          );
          const indexTVTarifa = r.data.findIndex(
            (tv) =>
              tv.tipovenda.descricao == "TARIFA NORMAL" ||
              tv.tipovenda.descricao == "PROMOCIONAL"
          );
          this.tipoVenda =
            this.tiposVenda[indexTVTarifa == -1 ? 0 : indexTVTarifa];
        })
        .catch((e) => {
          this.message.text = e;
          this.message.snacshow = true;
          this.message.type = "success";
        })
        .finally(() => this.$store.dispatch("activeLoader", false));
    },
    criarPassagem(poltrona, viagem) {
      this.$store.dispatch("activeLoader", true);

      this.passagensNaoVinculadas();
      this.blockPoltrona(poltrona);

      let client = null;
      if (this.passagensIda.length) {
        const passagemIndex = this.passagens.length - this.passagensIda.length;
        if (
          this.passagensIda[passagemIndex] &&
          !this.passagensIda[passagemIndex].crianca
        ) {
          client = this.passagensIda[passagemIndex].cliente;
        }
      }

      this.validTrechos();

      if (this.passagens.indexOf(this.poltrona) == -1) {
        if (
          poltrona.gratuidade &&
          (poltrona.gratuidade.descricao == "IDOSO 100%" ||
            poltrona.gratuidade.descricao == "DEFICIENTE 100%" ||
            poltrona.gratuidade.descricao == "ID JOVEM 100%")
        ) {
          this.$http
            .post(`pdv/tipovenda`, { desc: poltrona.gratuidade.descricao })
            .then((r) => {
              this.tipoVenda = r.data[0].id;
              this.passagens.push(
                ticketsData(
                  viagem,
                  this.tipoVenda,
                  poltrona,
                  this.$store.getters.getOpAgency,
                  this.sentido,
                  false,
                  client
                )
              );
              this.loadTipoVendaText(r.data[0].id);

              this.passagensNaoVinculadas();
              this.calcularTotal();
              this.checarPassagens();
            })
            .catch((e) => {
              this.message.text = e;
              this.message.snacshow = true;
              this.message.type = "success";
            });
        } else {
          //AQUI QUE COMECA A BAGACEIRA
          this.$http
            .post(`/pdv/tipovendas`, {
              viagens: this.selectedTrips,
              poltrona: poltrona,
              agencia: this.$store.getters.getOpAgency,
              empresa: this.$store.getters.getOpCompany,
            })
            .then((r) => {
              if (this.agencia.id !== 17) {
                this.tiposVenda = r.data.filter(
                  (value) => value.tipovenda.id !== 6 && value.tipovenda.id !== "9"
                );

                const indexTVTarifa = this.tiposVenda.findIndex(
                  (tv) =>
                  tv.tipovenda.descricao == "TARIFA NORMAL" ||
                  tv.tipovenda.descricao == "CASADINHA"
                );
                this.tipoVenda =
                  this.tiposVenda[
                    indexTVTarifa == -1 ? 0 : indexTVTarifa
                  ].tipovenda.id;
 
                this.passagens.push(
                  ticketsData(
                    viagem,
                    this.tipoVenda,
                    poltrona,
                    this.$store.getters.getOpAgency,
                    this.sentido,
                    false,
                    client
                  )
                );

                this.passagensNaoVinculadas();
                this.calcularTotal();
                this.checarPassagens();
                this.selectType(this.passagens[this.passagens.length - 1].uid);
              } else {
                this.tiposVenda = r.data.filter(
                  (value) => value.tipovenda.id !== "9"
                );
                const indexTVTarifa = r.data.findIndex(
                  (tv) =>
                    tv.tipovenda.descricao == "TARIFA NORMAL" ||
                    tv.tipovenda.descricao == "PROMOCIONAL"
                );
                this.tipoVenda =
                  this.tiposVenda[
                    indexTVTarifa == -1 ? 0 : indexTVTarifa
                  ].tipovenda.id;

                this.passagens.push(
                  ticketsData(
                    viagem,
                    this.tipoVenda,
                    poltrona,
                    this.$store.getters.getOpAgency,
                    this.sentido,
                    false,
                    client
                  ))
              }

            });
        }
      }
    },
    criarPassagemEmPe(viagem) {
      this.$store.dispatch("activeLoader", true);

      let client = null;
      if (this.passagensIda.length) {
        const passagemIndex = this.passagens.length - this.passagensIda.length;
        if (
          this.passagensIda[passagemIndex] &&
          !this.passagensIda[passagemIndex].crianca
        ) {
          client = this.passagensIda[passagemIndex].cliente;
        }
      }

      this.validTrechos();

      this.passagens.push(
        ticketsData(
          viagem,
          null,
          null,
          this.$store.getters.getOpAgency,
          this.sentido,
          false,
          client
        )
      );
      this.loadTiposVenda({
        viagens: viagem,
        poltrona: null,
        agencia: this.$store.getters.getOpAgency,
        empresa: this.$store.getters.getOpCompany,
      });
      this.checarPassagens();
      this.calcularTotal();
    },
    passagensNaoVinculadas() {
      let criancas = [];
      let criancasValores = [];
      for (let index = 0; index < this.passagens.length; index++) {
        if (this.passagens[index].crianca && !this.passagens[index].emPe) {
          criancas.push(index);
        }
        if (
          !this.passagens[index].passagemVinculada &&
          !this.passagens[index].emPe
        ) {
          criancasValores.push(index);
        }
        if (criancas.length == criancasValores.length && criancas.length > 0) {
          this.naoVinculadas = false;
        } else {
          this.naoVinculadas = true;
        }
      }
    },
    criarPassagemCrianca() {
      this.$store.dispatch("activeLoader", true);

      let passagem = null;
      for (let index = 0; index < this.passagens.length; index++) {
        if (
          !this.passagens[index > 0 ? index - 1 : index].crianca &&
          !this.passagens[index > 0 ? index - 1 : index].emPe
        ) {
          if (
            !this.passagens[index > 0 ? index - 1 : index].passagemVinculada &&
            !this.passagens[index > 0 ? index - 1 : index].emPe
          ) {
            passagem = this.passagens[index > 0 ? index - 1 : index];
          }
        } else if (
          !this.passagens[index].crianca &&
          !this.passagens[index].emPe
        ) {
          if (
            !this.passagens[index].passagemVinculada &&
            !this.passagens[index].emPe
          ) {
            passagem = this.passagens[index];
          }
        }
      }
      if (passagem) {
        passagem.passagemVinculada = true;

        this.validTrechos();

        this.$http.post(`pdv/tipovenda`, { desc: "CRIANÇA" }).then((r) => {
          this.tipoVenda = r.data[0].id;

          let client = null;
          if (this.passagensIda.length) {
            const passagemIndex =
              this.passagens.length - this.passagensIda.length;
            if (
              this.passagensIda[passagemIndex] &&
              this.passagensIda[passagemIndex].crianca
            ) {
              client = this.passagensIda[passagemIndex].cliente;
            }
          }

          this.passagens.push(
            ticketsData(
              [passagem.viagem],
              null,
              passagem.poltrona,
              this.$store.getters.getOpAgency,
              this.sentido,
              true,
              client
            )
          );

          this.passagensNaoVinculadas();
          this.checarPassagens();
        });
      }
    },
    removerPassagens(uid) {
      const idx = this.passagens.findIndex((passagem) => passagem.uid === uid);

      if (!this.passagens[idx].crianca && !this.passagens[idx].emPe) {
        this.desbloquearPoltrona(this.passagens[idx].poltrona);
      }

      if (!this.passagens[idx].emPe) {
        for (let index = 0; index < this.passagens.length; index++) {
          if (
            this.passagens[index].poltrona &&
            this.passagens[index].poltrona.numero ==
            this.passagens[idx].poltrona.numero &&
            !this.passagens[index].emPe
          ) {
            this.passagens[index].passagemVinculada = false;
          }
        }
      }

      if (!this.passagens[idx].emPe && !this.passagens[idx].crianca) {
        const childrenTickets = [...this.passagens];
        const checkChildrenTicketIndex = childrenTickets.findIndex(
          (passagem) =>
            passagem.poltronaVinculada === childrenTickets[idx].poltrona.numero
        );
        if (checkChildrenTicketIndex > -1) {
          this.passagens.splice(checkChildrenTicketIndex, 1);
          this.passagens.splice(idx, 1);
        } else {
          this.passagens.splice(idx, 1);
        }
      } else {
        this.passagens.splice(idx, 1);
      }

      this.calcularTotal();
      this.passagensNaoVinculadas();
    },
    nextStep() {
      this.stepperIndex++;
    },
    backStep() {
      this.stepperIndex--;
    },
    loadVendas() {
      for (let index = 0; index < this.passagens.length; index++) {
        if (!this.passagens[index].viagem.conexao) {
          this.passagens[index].venda.id_empresa =
            this.$store.getters.getOpCompany;
          this.passagens[index].venda.id_cliente =
            this.passagens[index].cliente;
          this.passagens[index].venda.id_operador =
            this.$store.getters.getOperador;
          this.passagens[index].venda.id_localidade_origem =
            this.passagens[index].viagem.origem.id_localidade;
          this.passagens[index].venda.id_localidade_destino =
            this.passagens[index].viagem.destino.id_localidade;
          this.passagens[index].venda.id_viagem =
            this.passagens[index].viagem.id_viagem;
          this.passagens[index].venda.data_hora_viagem =
            this.passagens[index].viagem.data_viagem +
            " " +
            this.passagens[index].viagem.origem.hora;
          this.passagens[index].venda.id_tarifa =
            this.passagens[index].viagem.tarifa.id;
          this.passagens[index].venda.id_tipovenda =
            this.passagens[index].tipoVenda;
          this.passagens[index].venda.id_formapagamento = "1";
          this.passagens[index].venda.id_linha =
            this.passagens[index].viagem.origem.id_linha;
          if (this.passagens[index].poltrona) {
            this.passagens[index].venda.id_poltrona =
              this.passagens[index].poltrona.id;
            this.passagens[index].venda.id_tiposervico =
              this.passagens[index].poltrona.id_tipo_servico;
          }
          // VALORES ***************************************************************************************
          // VALOR  TOTAL ***************************************************************************************
          this.passagens[index].venda.valor_total =
            this.passagens[index].viagem.tarifa.valor;
          // VALOR  TOTAL ***************************************************************************************
          // VALOR A PAGAR ***************************************************************************************
          if (this.agencia.taxa_embarque) {
            this.passagens[index].venda.valor_apagar =
              parseFloat(this.passagens[index].valor) +
              parseFloat(this.agencia.taxa_embarque);
            this.passagens[index].venda.taxaembarque =
              this.agencia.taxa_embarque;
          }
          // VALOR A PAGAR ***************************************************************************************
          // VALOR DO DESCONTO  ***************************************************************************************
          if (this.passagens[index].tipoVenda == 3) {
            this.passagens[index].venda.desconto =
              this.passagens[index].viagem.tarifa.valor / 2;
          } else if (this.passagens[index].tipoVenda == 4) {
            this.passagens[index].venda.desconto =
              this.passagens[index].viagem.tarifa.valor / 2;
          } else if (this.passagens[index].tipoVenda == 2) {
            this.passagens[index].venda.desconto =
              this.tiposVenda[0].desconto[0].valor;
          } else if (this.passagens[index].tipoVenda == 1) {
            this.passagens[index].venda.desconto =
              this.tiposVenda[0].desconto[0].valor;
            this.passagens[index].venda.taxaembarque = parseFloat(
              this.agencia.taxa_embarque
            );
          } else if (this.passagens[index].tipoVenda == 10) {
            this.passagens[index].venda.desconto = 0;
          } else {
            this.passagens[index].venda.desconto = 0;
          }

          if (
            this.passagens[index].tipoVenda == 6 ||
            this.passagens[index].tipoVenda == 7 ||
            this.passagens[index].tipoVenda == 9 ||
            this.passagens[index].tipoVenda == 8
          ) {
            this.passagens[index].venda.taxaembarque = 0;
            this.passagens[index].venda.desconto =
              this.passagens[index].viagem.tarifa.valor;
          } else if (this.passagens[index].tipoVenda == 10) {
            this.passagens[index].venda.desconto = 0;
          }

          if (this.passagens[index].crianca) {
            this.passagens[index].venda.desconto =
              this.passagens[index].viagem.tarifa.valor;
            this.passagens[index].venda.taxaembarque = 0;
            this.passagens[index].venda.valor_apagar = 0;
          }
        } else {
          // TRECHO 1  ***************************************************************************************
          this.passagens[index].vendaTrecho1.id_empresa =
            this.$store.getters.getOpCompany;
          this.passagens[index].vendaTrecho1.id_operador =
            this.$store.getters.getOperador;
          this.passagens[index].vendaTrecho1.id_localidade_origem =
            this.passagens[index].viagem[0].id_localidade_origem_inicial;
          this.passagens[index].vendaTrecho1.id_localidade_destino =
            this.passagens[index].viagem[0].id_localidade_destino_inicial;
          this.passagens[index].vendaTrecho1.id_viagem =
            this.passagens[index].viagem.id_viagem;
          this.passagens[index].vendaTrecho1.id_linha =
            this.passagens[index].viagem[0].id_linha_inicial;
          this.passagens[index].vendaTrecho1.data_hora_viagem =
            this.passagens[index].viagem.data_viagem +
            " " +
            this.passagens[index].viagem.conexao[0].origem_inicial.hora;
          this.passagens[index].vendaTrecho1.id_tipovenda =
            this.passagens[index].tipoVenda;
          this.passagens[index].vendaTrecho1.id_tarifa =
            this.passagens[index].viagem.conexao[0].tarifa.id;
          this.passagens[index].vendaTrecho1.id_formapagamento = "1";
          if (this.passagens[index].poltrona) {
            this.passagens[index].vendaTrecho1.id_poltrona =
              this.passagens[index].poltrona.id;
            this.passagens[index].vendaTrecho1.id_tiposervico =
              this.passagens[index].poltrona.id_tipo_servico;
          } else {
            this.passagens[index].vendaTrecho1.id_tiposervico =
              this.passagens[index].viagem.onibus.id_tipo_servico;
          }
          this.passagens[index].vendaTrecho1.id_cliente =
            this.passagens[index].cliente;

          if (this.agencia.taxa_embarque) {
            this.passagens[index].vendaTrecho1.valor_apagar =
              parseFloat(this.passagens[index].valor) +
              parseFloat(this.agencia.taxa_embarque);
            this.passagens[index].vendaTrecho1.taxaembarque =
              this.agencia.taxa_embarque;
          }

          // VALORES TRECHO 1 ***************************************************************************************
          let desconto = this.passagens[index].viagem.desconto_primeira_rota
            ? this.passagens[index].viagem.desconto_primeira_rota
            : 0;

          if (this.passagens[index].tipoVenda == 3) {
            //IDOSO 50%
            let c =
              (this.passagens[index].viagem.conexao[0].tarifa - desconto) / 2;
            let calculada = c < 0 ? c * -1 : c;

            this.passagens[index].vendaTrecho1.valor_apagar = calculada;
            this.passagens[index].vendaTrecho1.desconto = calculada;
          } else if (this.passagens[index].tipoVenda == 4) {
            //ESTUDANTE 50%
            let c =
              (this.passagens[index].viagem.conexao[0].tarifa - desconto) / 2;
            let calculada = c < 0 ? c * -1 : c;
            this.passagens[index].vendaTrecho1.valor_apagar = calculada;
            this.passagens[index].vendaTrecho1.desconto = calculada;
          } else if (this.passagens[index].tipoVenda == 6) {
            //CORTESIA
            this.passagens[index].vendaTrecho1.valor_apagar = 0;
            this.passagens[index].vendaTrecho1.desconto =
              this.passagens[index].viagem.conexao[0].tarifa;
            this.passagens[index].vendaTrecho1.taxaembarque = 0;
          } else if (this.passagens[index].tipoVenda == 7) {
            // IDOSO 100%
            this.passagens[index].vendaTrecho1.valor_apagar = 0;
            this.passagens[index].vendaTrecho1.desconto =
              this.passagens[index].viagem.conexao[0].tarifa;
          } else if (this.passagens[index].tipoVenda == 8) {
            // ID JOVEM 100%
            this.passagens[index].vendaTrecho1.valor_apagar = 0;
            this.passagens[index].vendaTrecho1.desconto =
              this.passagens[index].viagem.conexao[0].tarifa;
          } else if (this.passagens[index].tipoVenda == 9) {
            // DEFICIENTE 100%
            this.passagens[index].vendaTrecho1.valor_apagar = 0;
            this.passagens[index].vendaTrecho1.desconto =
              this.passagens[index].viagem.conexao[0].tarifa;
          } else if (this.passagens[index].tipoVenda == 2) {
            // PROMOCIONAL
            this.passagens[index].vendaTrecho1.valor_total =
              this.passagens[index].viagem.conexao[0].tarifa;
            this.passagens[index].vendaTrecho1.desconto =
              parseFloat(this.tiposVenda[0].desconto[0].valor) / 2 +
              (this.passagens[index].viagem.desconto_primeira_rota
                ? parseFloat(
                  this.passagens[index].viagem.desconto_primeira_rota
                )
                : 0);
            this.passagens[index].vendaTrecho1.valor_apagar =
              parseFloat(this.passagens[index].viagem.conexao[0].tarifa) -
              this.passagens[index].vendaTrecho1.desconto;
          } else if (this.passagens[index].tipoVenda == 1) {
            // CASADINHA
            this.passagens[index].vendaTrecho1.valor_total =
              this.passagens[index].viagem.conexao[0].tarifa;
            this.passagens[index].vendaTrecho1.desconto =
              parseFloat(this.tiposVenda[0].desconto[0].valor) / 2 +
              (this.passagens[index].viagem.desconto_primeira_rota
                ? parseFloat(
                  this.passagens[index].viagem.desconto_primeira_rota
                )
                : 0);
            this.passagens[index].vendaTrecho1.valor_apagar =
              parseFloat(this.passagens[index].viagem.conexao[0].tarifa) -
              this.passagens[index].vendaTrecho1.desconto;
          } else if (this.passagens[index].tipoVenda == 10) {
            // TARIFA NORMAL
            this.passagens[index].vendaTrecho1.valor_total =
              this.passagens[index].viagem.conexao[0].tarifa;
            this.passagens[index].vendaTrecho1.desconto = this.passagens[index]
              .viagem.desconto_primeira_rota
              ? parseFloat(this.passagens[index].viagem.desconto_primeira_rota)
              : 0;
            this.passagens[index].vendaTrecho1.valor_apagar = parseFloat(
              this.passagens[index].viagem.desconto_primeira_rota
                ? this.passagens[index].viagem.conexao[0].tarifa -
                this.passagens[index].viagem.desconto_primeira_rota
                : this.passagens[index].viagem.conexao[0].tarifa
            );
          }

          this.passagens[index].vendaTrecho1.taxaembarque = parseFloat(
            this.agencia.taxa_embarque
          );

          if (this.passagens[index].crianca) {
            this.passagens[index].vendaTrecho1.desconto =
              this.passagens[index].viagem.conexao[0].tarifa;
            this.passagens[index].vendaTrecho1.taxaembarque = 0;
            this.passagens[index].vendaTrecho1.valor_apagar = 0;
          }

          // this.passagens[index].vendaTrecho1.valor_apagar =
          //   parseFloat(
          //     this.passagens[index].vendaTrecho1.valor_total -
          //       this.passagens[index].vendaTrecho1.desconto
          //   ) +
          //   parseFloat(this.passagens[index].vendaTrecho1.taxaembarque) / 2;

          if (
            this.passagens[index].tipoVenda == 6 ||
            this.passagens[index].tipoVenda == 7 ||
            this.passagens[index].tipoVenda == 9 ||
            this.passagens[index].tipoVenda == 8
          ) {
            this.passagens[index].vendaTrecho1.valor_total =
              this.passagens[index].viagem.conexao[0].tarifa;
            this.passagens[index].vendaTrecho1.desconto =
              this.passagens[index].viagem.conexao[0].tarifa;
            this.passagens[index].vendaTrecho1.valor_apagar = 0;
            this.passagens[index].vendaTrecho1.taxaembarque = 0;
          }

          // VALORES TRECHO 1 ***************************************************************************************

          // TRECHO 2 ****************************************************************************************
          this.passagens[index].vendaTrecho2.id_empresa =
            this.$store.getters.getOpCompany;
          this.passagens[index].vendaTrecho2.id_operador =
            this.$store.getters.getOperador;
          this.passagens[index].vendaTrecho2.id_localidade_origem =
            this.passagens[index].viagem[0].id_localidade_origem_final;
          this.passagens[index].vendaTrecho2.id_localidade_destino =
            this.passagens[index].viagem[0].id_localidade_destino_final;
          this.passagens[index].vendaTrecho2.id_viagem =
            this.passagens[index].viagem.id_viagem;
          this.passagens[index].vendaTrecho2.id_linha =
            this.passagens[index].viagem[0].id_linha_final;
          this.passagens[index].vendaTrecho2.data_hora_viagem =
            this.passagens[index].viagem.data_viagem +
            " " +
            this.passagens[index].viagem.conexao[1].origem_final.hora;
          this.passagens[index].vendaTrecho2.id_tipovenda =
            this.passagens[index].tipoVenda;
          this.passagens[index].vendaTrecho2.id_tarifa =
            this.passagens[index].viagem.conexao[1].tarifa.id;
          this.passagens[index].vendaTrecho2.id_formapagamento = "1";
          if (this.passagens[index].poltrona) {
            this.passagens[index].vendaTrecho2.id_poltrona =
              this.passagens[index].poltrona.id;
            this.passagens[index].vendaTrecho2.id_tiposervico =
              this.passagens[index].poltrona.id_tipo_servico;
          } else {
            this.passagens[index].vendaTrecho2.id_tiposervico =
              this.passagens[index].viagem.onibus.id_tipo_servico;
          }
          this.passagens[index].vendaTrecho2.id_cliente =
            this.passagens[index].cliente;

          if (this.agencia.taxa_embarque) {
            this.passagens[index].vendaTrecho2.valor_apagar =
              parseFloat(this.passagens[index].valor) +
              parseFloat(this.agencia.taxa_embarque);
            this.passagens[index].vendaTrecho2.taxaembarque =
              this.agencia.taxa_embarque;
          }

          // VALORES TRECHO 2 ***************************************************************************************
          if (this.passagens[index].tipoVenda == 3) {
            let c =
              (this.passagens[index].viagem.conexao[1].tarifa - desconto) / 2;
            let calculada = c < 0 ? c * -1 : c;
            this.passagens[index].vendaTrecho2.valor_apagar = calculada;
            this.passagens[index].vendaTrecho2.desconto = calculada;
          } else if (this.passagens[index].tipoVenda == 4) {
            let c =
              (this.passagens[index].viagem.conexao[1].tarifa - desconto) / 2;
            let calculada = c < 0 ? c * -1 : c;
            this.passagens[index].vendaTrecho2.valor_apagar = calculada;
            this.passagens[index].vendaTrecho2.desconto = calculada;
          } else if (this.passagens[index].tipoVenda == 6) {
            this.passagens[index].vendaTrecho2.valor_apagar = 0;
            this.passagens[index].vendaTrecho2.desconto =
              this.passagens[index].viagem.conexao[1].tarifa;
            this.passagens[index].vendaTrecho1.taxaembarque = 0;
          } else if (this.passagens[index].tipoVenda == 7) {
            this.passagens[index].vendaTrecho2.valor_apagar = 0;
            this.passagens[index].vendaTrecho2.desconto =
              this.passagens[index].viagem.conexao[1].tarifa;
          } else if (this.passagens[index].tipoVenda == 8) {
            this.passagens[index].vendaTrecho2.valor_apagar = 0;
            this.passagens[index].vendaTrecho2.desconto =
              this.passagens[index].viagem.conexao[1].tarifa;
          } else if (this.passagens[index].tipoVenda == 9) {
            this.passagens[index].vendaTrecho2.valor_apagar = 0;
            this.passagens[index].vendaTrecho2.desconto =
              this.passagens[index].viagem.conexao[1].tarifa;
          } else if (this.passagens[index].tipoVenda == 2) {
            this.passagens[index].vendaTrecho2.valor_total =
              this.passagens[index].viagem.conexao[1].tarifa;
            this.passagens[index].vendaTrecho2.desconto =
              parseFloat(this.tiposVenda[0].desconto[0].valor) / 2 +
              (this.passagens[index].viagem.desconto_segunda_rota
                ? parseFloat(this.passagens[index].viagem.desconto_segunda_rota)
                : 0);
            this.passagens[index].vendaTrecho2.valor_apagar =
              parseFloat(this.passagens[index].viagem.conexao[1].tarifa) -
              this.passagens[index].vendaTrecho2.desconto;
          } else if (this.passagens[index].tipoVenda == 1) {
            this.passagens[index].vendaTrecho2.valor_total =
              this.passagens[index].viagem.conexao[1].tarifa;
            this.passagens[index].vendaTrecho2.desconto =
              parseFloat(this.tiposVenda[0].desconto[0].valor) / 2 +
              (this.passagens[index].viagem.desconto_segunda_rota
                ? parseFloat(this.passagens[index].viagem.desconto_segunda_rota)
                : 0);
            this.passagens[index].vendaTrecho2.valor_apagar =
              parseFloat(this.passagens[index].viagem.conexao[1].tarifa) -
              this.passagens[index].vendaTrecho2.desconto;
          } else if (this.passagens[index].tipoVenda == 10) {
            this.passagens[index].vendaTrecho2.valor_total =
              this.passagens[index].viagem.conexao[1].tarifa;
            this.passagens[index].vendaTrecho2.desconto = this.passagens[index]
              .viagem.desconto_segunda_rota
              ? parseFloat(this.passagens[index].viagem.desconto_segunda_rota)
              : 0;
            this.passagens[index].vendaTrecho2.valor_apagar = parseFloat(
              this.passagens[index].viagem.desconto_segunda_rota
                ? this.passagens[index].viagem.conexao[1].tarifa -
                this.passagens[index].viagem.desconto_segunda_rota
                : this.passagens[index].viagem.conexao[1].tarifa
            );
          }
          this.passagens[index].vendaTrecho2.taxaembarque = parseFloat(
            this.agencia.taxa_embarque
          );

          if (
            this.passagens[index].tipoVenda == 6 ||
            this.passagens[index].tipoVenda == 7 ||
            this.passagens[index].tipoVenda == 9 ||
            this.passagens[index].tipoVenda == 8
          ) {
            this.passagens[index].vendaTrecho2.valor_total =
              this.passagens[index].viagem.conexao[1].tarifa;
            this.passagens[index].vendaTrecho2.desconto =
              this.passagens[index].viagem.conexao[1].tarifa;
            this.passagens[index].vendaTrecho2.valor_apagar = 0;
            this.passagens[index].vendaTrecho2.taxaembarque = 0;
          }

          if (this.passagens[index].crianca) {
            this.passagens[index].vendaTrecho2.desconto =
              this.passagens[index].viagem.conexao[1].tarifa;
            this.passagens[index].vendaTrecho2.taxaembarque = 0;
            this.passagens[index].vendaTrecho2.valor_apagar = 0;
          }
          // VALORES TRECHO 2 ***************************************************************************************
        }
      }
    },
    modificarFormaPagamento() {
      for (let index = 0; index < this.passagens.length; index++) {
        if (!this.passagens[index].viagem.conexao) {
          this.passagens[index].venda.id_formapagamento = this.formaPagamento;
        } else {
          this.passagens[index].vendaTrecho1.id_formapagamento =
            this.formaPagamento;
          this.passagens[index].vendaTrecho2.id_formapagamento =
            this.formaPagamento;
        }
      }
    },
    blockPoltrona(seats) {
      const enabledPoltrona = this.onibus.findIndex(
        (o) => o.poltrona == seats.poltrona
      );

      this.onibus[enabledPoltrona].disabled = true;
    },
    desbloquearPoltrona(seats) {
      const removePoltrona = this.onibus.findIndex(
        (o) => o.poltrona == seats.poltrona
      );
      this.onibus[removePoltrona].disabled = false;
    },
    loadTipos() {
      this.$http
        .get(`/tiposservico`)
        .then((r) => {
          this.tiposServicos = r.data;
          this.validTrechos();
          this.$store.dispatch("activeLoader", false);
        })
        .catch((e) => {
          this.$store.dispatch("activeLoader", false);
          this.message.text = e;
          this.message.snacshow = true;
          this.message.type = "success";
        });
    },
    loadTrechos() {
      this.$http
        .get(`/pdv/localidades`)
        .then((r) => {
          this.trechos = r.data;
          this.validTrechos();
          this.$store.dispatch("activeLoader", false);
        })
        .catch((e) => {
          this.$store.dispatch("activeLoader", false);
          this.message.text = e;
          this.message.snacshow = true;
          this.message.type = "success";
        });
    },
    loadViagem(id) {
      this.$http
        .get(`/pdv/viagens/${id}`)
        .then((r) => {
          this.viagem = r.data[0];
          this.$store.dispatch("activeLoader", false);
        })
        .catch((e) => {
          this.$store.dispatch("activeLoader", false);
          this.message.text = e;
          this.message.snacshow = true;
          this.message.type = "success";
        });
    },
    refresh() {
      location.reload(1);
    },
    loadViagens(search) {
      this.$store.dispatch("activeLoader", true);
      this.onibus = [];
      this.trips = [];

      this.$http
        .post(`/pdv/viagens/filter`, search)
        .then((r) => {
          if (!r.data.error) {
            const resultData = [...r.data];
            if (!search.data_final) {
              resultData.forEach((element) => {
                element.sentido = "IDA";
              });
              this.trips = resultData;
            } else {
              resultData.forEach((element) => {
                if (element.data_viagem == search.data_final) {
                  element.sentido = "VOLTA";
                } else {
                  element.sentido = "IDA";
                }
              });
              this.trips = resultData;
            }
            this.selectedTrips = [];
            this.passagens = [];
            this.$store.dispatch("activeLoader", false);
          } else {
            this.$store.dispatch("activeLoader", false);
            this.message.text = r.data.error;
            this.message.snacshow = true;
            this.message.type = "warning";
          }
        })
        .catch((e) => {
          this.$store.dispatch("activeLoader", false);
          this.message.text = e;
          this.message.snacshow = true;
          this.message.type = "success";
        });
    },
    loadLinhas() {
      this.$http
        .get(`/linhas`)
        .then((r) => {
          this.linhas = r.data;
          this.$store.dispatch("activeLoader", false);
        })
        .catch((e) => {
          this.$store.dispatch("activeLoader", false);
          this.message.text = e;
          this.message.snacshow = true;
          this.message.type = "success";
        });
    },
    selectedTravel(viagem, i) {
      if (this.selectedTrips.length <= 1) {
        if (this.selectedTrips.indexOf(viagem) == -1) {
          if (
            this.selectedTrips.length > 0 &&
            this.selectedTrips[0].data_viagem == viagem.data_viagem
          ) {
            alert(
              "A casadinha só é possível com a selecao da data de retorno!"
            );
          } else {
            this.selectedTrips.push(viagem);
            this.trips[i].selecao = "#279e4d";
            this.trips[i].indice = this.selectedTrips.length - 1;
          }
        } else {
          this.selectedTrips.splice(viagem, 1);
          this.trips[i].selecao = "rgb(12, 120, 209)";
        }
      } else if (viagem.selecao == "#279e4d") {
        this.selectedTrips.splice(this.trips[i].indice, 1);
        this.trips[i].selecao = "rgb(12, 120, 209)";
      } else {
        alert(
          "Só é possível selecionar 1 viagem em cada data! Com excessão da casadinha que são 2 datas diferentes"
        );
      }
    },
    advancePdv() {
      this.loadBus(this.selectedTrips[0]);
    },

    getBus(viagensSelecionada, returnPage) {
      this.loadBus(viagensSelecionada, returnPage);
    },
    // CHECAR SE PASSAGENS ESTAO COMPLETAS
    checarPassagens() {
      let tempTickets = [];
      for (let index = 0; index < this.passagens.length; index++) {
        if (
          this.passagens[index].tipoVenda != "" &&
          this.passagens[index].cliente
        ) {
          tempTickets.push(this.passagens[index]);
        }
      }

      this.mostrarTotal = tempTickets.length == this.passagens.length;
    },
    // CHECAR SE PASSAGENS ESTAO COMPLETAS
    async loadBus(selectTravel, returnPage = false) {
      this.$store.dispatch("activeLoader", true);
      const conexao = selectTravel.conexao ? selectTravel.conexao : null;
      const { data } = await this.$http.post(`/pdv/bus`, {
        id_viagem: selectTravel.id_viagem,
        id_bus: selectTravel.onibus.id,
        origem: {
          id_localidade: selectTravel.origem.id_localidade,
          id_linha: selectTravel.origem.id_linha,
          hora: selectTravel.origem.hora,
        },
        destino: {
          id_localidade: selectTravel.destino.id_localidade,
        },
        gratuidades: selectTravel.gratuidades,
        conexao,
        data: selectTravel.data_viagem,
      });

      this.onibus = data;
      if (!returnPage) {
        this.nextStep();
      }
      this.$store.dispatch("activeLoader", false);
    },
    loadBusUnlock(idBus, selectTravel) {
      this.$store.dispatch("activeLoader", true);
      this.$http
        .post(`/pdv/bus/${idBus}`, selectTravel)
        .then((r) => {
          if (!r.data.result) {
            this.onibus = r.data;
            this.sentido = selectTravel[0].sentido;
            selectTravel[0].sentido == "IDA"
              ? [this.$router.replace("/pdv#tab-1"), (this.tabs = "#tab-1")]
              : selectTravel[0].sentido == "VOLTA"
                ? [this.$router.replace("/pdv#tab-2"), (this.tabs = "#tab-2")]
                : null;
            this.$store.dispatch("activeLoader", false);
          } else {
            this.message.text = r.data.result;
            this.message.snacshow = true;
            this.message.type = "warning";
          }
        })
        .catch((e) => {
          this.$store.dispatch("activeLoader", false);
          this.message.text = e;
          this.message.snacshow = true;
          this.message.type = "success";
        });
    },
    loadAgency() {
      this.$http
        .get(`/pdv/agencias/${this.$store.getters.getOpAgency}`)
        .then((r) => {
          this.agencia = r.data[0];
          this.loadTrechos();
          this.$store.dispatch("activeLoader", false);
        })
        .catch((e) => {
          this.$store.dispatch("activeLoader", false);
          this.message.text = e;
          this.message.snacshow = true;
          this.message.type = "success";
        });
    },
    getClient(id) {
      this.$http.get(`/pdv/client/${id}`).then((r) => {
        this.pdvCliente = r.data[0];
        this.$store.dispatch("activeLoader", false);
      });
    },
    formasPagamento() {
      this.$http.get(`/formaspagamento`).then((r) => {
        this.formasdePagamento = r.data;

        r.data.some((value) => {
          if (value.descricao.toLowerCase() === "pix") {
            this.pixId = value.id;
            return true;
          }
          return false;
        });

        this.$store.dispatch("activeLoader", false);
      });
    },
    loadEmpresa(id) {
      this.$http
        .get(`/empresas/${id}`)
        .then((r) => {
          this.empresa = r.data[0];
          this.$store.dispatch("activeLoader", false);
        })
        .catch((e) => {
          this.$store.dispatch("activeLoader", false);
          this.message.text = e;
          this.message.snacshow = true;
          this.message.type = "success";
        });
    },
    valorBrasileiro(v) {
      return v.toLocaleString("pt-br", { minimumFractionDigits: 2 });
    },
    valorAmericano(v) {
      return v.toLocaleString("en", { minimumFractionDigits: 2 });
    },
    convertDate(date) {
      return date.split("-").reverse().join("/");
    },
    // INICIO DO PROCEDIMENTO DE VALIDACAO FORMULARIO DO CLIENTE *********************************************************************************************************************
    enviarVendas() {
      this.$store.dispatch("activeLoader", true);
      this.sendBPe = true;
      this.$http
        .post(`/vendas/finalize`, this.passagens)
        .then((r) => {
          if (r.data.result) {
            this.$store.dispatch("activeLoader", false);
            this.message.text = r.data.result;
            this.message.snacshow = true;
            this.message.type = "success";
            setTimeout(() => {
              Object.assign(this.$data, this.$options.data.apply(this));
              this.onCreated();
              this.onMounted();
              this.$refs.searchPassagens.clear();
            }, 2000);
          } else {
            this.$store.dispatch("activeLoader", false);
            this.message.text =
              r.data.error +
              "Não foi possível emitir o BPe, entre em contato com o suporte técnico!";
            this.message.snacshow = true;
            this.message.type = "error";
            this.message.timeout = 30000;
            // setTimeout(() => {
            //   location.reload(1);
            // }, 2000);
          }
          this.$store.dispatch("activeLoader", false);
        })
        .catch((e) => {
          this.$store.dispatch("activeLoader", false);
          this.message.text =
            e +
            "Não foi possível emitir o BPe, entre em contato com o suporte técnico!";
          this.message.snacshow = true;
          this.message.type = "error";
          this.message.timeout = 30000;
        });
    },
    async vendaPix() {
      this.pixAwaitingPayment = true;
      this.brcode = null;
      this.dialogPix = true;

      const responseCob = await axios.post(
        `${api2UrlByEnviroment[process.env.NODE_ENV.trim()]}/pix`,
        {
          amount: this.valorFinal.toFixed(2),
        }
      );
      this.brcode = responseCob.data.brcode;

      while (this.pixAwaitingPayment) {
        const [cob] = await Promise.all([
          axios.get(`${api2UrlByEnviroment[process.env.NODE_ENV.trim()]}/pix/${responseCob.data.txid}`),
          new Promise((resolve) => setTimeout(resolve, 3000)),
        ]);

        if (cob.data.status === "CONCLUIDA") {
          this.pixAwaitingPayment = false;
          this.enviarVendas();
          this.dialogPix = false;
        }
      }
    },
    cancelPix() {
      this.pixAwaitingPayment = false;
      this.dialogPix = false;
    },
    checkCashboxOpen() {
      this.$http
        .post(`agencias/check-cashbox-open`, {
          agency_id: this.$store.getters.getOpAgency,
          operator_id: this.$store.getters.getOperador,
        })
        .then((response) => {
          if (!response.data.status) {
            if (this.$store.getters.getOperadorLevel >= 5) {
              this.showModal = true;
              this.$router.replace("/pdv/financeiro/caixa-bilheteiro");
            }
            return;
          }
        });
    },
    calculateChange() {
      const amount = this.valorPago.replace(".", "");

      return (parseFloat(amount) - parseFloat(this.valorFinal)).toLocaleString(
        "pt-br",
        { minimumFractionDigits: 2 }
      ) != "NaN"
        ? (parseFloat(amount) - parseFloat(this.valorFinal)).toLocaleString(
          "pt-br",
          { minimumFractionDigits: 2 }
        )
        : "0,00";
    },
  },
};
</script>
