import { v4 as uuidv4 } from "uuid";

export const ticketsData = (
  tripData,
  saleTypeId = null,
  seat = null,
  agency,
  direction,
  children = false,
  client = null,
  saleTypeDesc = null
) => {
  const normalTicket = (index) => {
    return {
      uid: uuidv4(),
      passagemVinculada: false,
      viagem: tripData[index],
      poltrona: seat,
      venda: {
        cod_barra: null,
        id_empresa: null,
        id_localidade_origem: null,
        id_localidade_destino: null,
        id_viagem: null,
        data_hora_viagem: null,
        id_poltrona: null,
        id_linha: null,
        id_tiposervico: null,
        id_tipovenda: null,
        id_operador: null,
        id_tarifa: null,
        pedagio: 0.0,
        taxaembarque: 0.0,
        seguro: 0.0,
        outros: 0.0,
        valor_total: 0.0,
        desconto: 0.0,
        valor_apagar: 0.0,
        id_formapagamento: null,
        id_cliente: null,
      },
      tipoVenda: saleTypeId,
      agencia: agency,
      valor: 0,
      poltronaVinculada: 0,
    };
  };

  const conectionTicket = (index) => {
    return {
      passagemVinculada: false,
      viagem: tripData[index],
      poltrona: seat,
      vendaTrecho1: {
        cod_barra: null,
        id_empresa: null,
        id_localidade_origem: null,
        id_localidade_destino: null,
        id_viagem: null,
        data_hora_viagem: null,
        id_poltrona: null,
        id_linha: null,
        id_tiposervico: null,
        id_tipovenda: null,
        id_operador: null,
        id_tarifa: null,
        pedagio: 0.0,
        taxaembarque: 0.0,
        seguro: 0.0,
        outros: 0.0,
        valor_total: 0.0,
        desconto: 0.0,
        valor_apagar: 0.0,
        id_formapagamento: null,
        id_cliente: null,
      },
      vendaTrecho2: {
        cod_barra: null,
        id_empresa: null,
        id_localidade_origem: null,
        id_localidade_destino: null,
        id_viagem: null,
        data_hora_viagem: null,
        id_poltrona: null,
        id_linha: null,
        id_tiposervico: null,
        id_tipovenda: null,
        id_operador: null,
        id_tarifa: null,
        pedagio: 0.0,
        taxaembarque: 0.0,
        seguro: 0.0,
        outros: 0.0,
        valor_total: 0.0,
        desconto: 0.0,
        valor_apagar: 0.0,
        id_formapagamento: null,
        id_cliente: null,
      },
      tipoVenda: saleTypeId,
      agencia: agency,
      valor: 0,
      linkedArmchair: 0,
    };
  };

  const mountTrip = () => {
    const result = tripData.map((trip, index) => {
      if (direction === "IDA" || direction === "VOLTA") {
        if (!trip.conexao) {
          return normalTicket(index);
        } else {
          return conectionTicket(index);
        }
      }
    });

    return result[0];
  };

  const checkTrip = () => {
    const resultMount = mountTrip();
    if (children) {
      resultMount.crianca = true;
      resultMount.poltronaVinculada = seat.numero;
      resultMount.id_tipovenda = 11;
      resultMount.tipoVenda = 11;
    } else {
      delete resultMount.crianca;
      delete resultMount.poltronaVinculada;
    }

    if (!seat) {
      resultMount.emPe = true;
      delete resultMount.poltrona;
      delete resultMount.id_poltrona;
    }

    resultMount.cliente = client;
    resultMount.tipoVendaDesc = saleTypeDesc;

    return resultMount;
  };

  return checkTrip();
};
