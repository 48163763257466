<script>
import ClientModal from "./ClientModal";
import UserForm from "./user";
import { Swiper, SwiperSlide } from "swiper-vue2";
import "swiper/swiper-bundle.css";

export default {
  data: () => ({
    showClientModal: false,
    type: null,
  }),
  components: {
    ClientModal,
    Swiper,
    SwiperSlide,
    UserForm,
  },
  props: {
    travels: Array,
    saleTypes: Array,
    selectedClient: String,
    isRemarcacao: Boolean,
  },
  methods: {
    selectType(uid) {
      this.$emit("selectType", uid);
    },
    removeTickets(uid) {
      this.$emit("removeTicket", uid);
    },
    selectClient(clientId) {
      this.$emit("selectClient", clientId);
    },
    checkTickets() {
      this.$emit("checarPassagens");
    },
    openNewClientModal(show = false, type = null) {
      this.showClientModal = !show;
      this.type = type;
    },
  },
};
</script>
