<script>
import {
  LeftTravelCard,
  RightTravelCard,
  RightTravelText,
  RightTravelTimeText,
  RightTravelContent,
  TravelCardContainer,
  LeftTravelCardContainer,
  LeftTravelCardContent,
  LeftTravelCardFooter,
  LeftTravelCardColorText,
} from "./styled";

import CardSingleTravel from "../CardSingleTravel";
import Helpers from "@/mixins";
export default {
  components: {
    LeftTravelCard,
    RightTravelCard,
    RightTravelTimeText,
    RightTravelText,
    RightTravelContent,
    TravelCardContainer,
    LeftTravelCardContainer,
    LeftTravelCardContent,
    LeftTravelCardFooter,
    CardSingleTravel,
    LeftTravelCardColorText,
  },
  mixins: [Helpers],
  props: ["data", "index"],
  data: () => ({
    showModal: false,
    gratuitiesElderly: 0,
    gratuitiesYoung: 0,
    gratuitiesDeficient: 0,
  }),
  created() {
    if (this.data && this.data.gratuidades.length) {
      this.data.gratuidades.forEach((item) => {
        if (item.gratuidade.descricao === "IDOSO 100%") {
          this.gratuitiesElderly = item.totalPoltronas.length;
        } else if (item.gratuidade.descricao === "ID JOVEM 100%") {
          this.gratuitiesYoung = item.totalPoltronas.length;
        } else if (item.gratuidade.descricao === "DEFICIENTE 100%") {
          this.gratuitiesDeficient = item.totalPoltronas.length;
        }
      });
    }
  },
  mounted() {
    this.Helpers;
  },
  methods: {
    showOrCloseModal() {
      this.showModal = !this.showModal;
    },
  },
};
</script>
