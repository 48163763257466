<script>
import {
  BusContainer,
  BusContent,
  BusSeatsContainer,
  BusSeatContentTopRow,
  BusSeatContentBottomRow,
  BusArrowIconButton,
} from "./styles";

import BusFront from "./BusFront";
import BusBack from "./BusBack";
import Seat from "../Seat";
export default {
  props: ["seats", "selectedTrips", "tabindex"],
  data: () => ({
    showSecondFloor: false,
  }),
  components: {
    BusContent,
    BusSeatsContainer,
    BusArrowIconButton,
    BusSeatsContainer,
    BusSeatContentTopRow,
    BusSeatContentBottomRow,
    BusContainer,
    BusFront,
    BusBack,
    Seat,
  },
  methods: {
    setSecondFloor() {
      this.showSecondFloor = !this.showSecondFloor;
    },
    makeTicket(seat, selectedTrips) {
      this.$emit("criarPassagem", seat, selectedTrips);
    },
  },
  watch: {
    seats() {
      const desabilitadas = parseInt(this.selectedTrips[0].desabilitadas, 10);

      if (desabilitadas > 0) {
        const qtdPorFila = Math.floor(desabilitadas / 4);
        var qtdRestante = desabilitadas - 4 * qtdPorFila;

        const fila = this.seats
          .filter((value, index) => index <= 14 && value.numero)
          .slice(qtdRestante > 0 ? -Math.abs(qtdPorFila + 1) : -qtdPorFila);
        qtdRestante -= 1;
        const fila2 = this.seats
          .filter((value, index) => index > 14 && index <= 29 && value.numero)
          .slice(qtdRestante > 0 ? -Math.abs(qtdPorFila + 1) : -qtdPorFila);
        qtdRestante -= 1;
        const fila3 = this.seats
          .filter((value, index) => index > 29 && index <= 44 && value.numero)
          .slice(qtdRestante > 0 ? -Math.abs(qtdPorFila + 1) : -qtdPorFila);
        qtdRestante -= 1;
        const fila4 = this.seats
          .filter((value, index) => index > 44 && index <= 59 && value.numero)
          .slice(qtdRestante > 0 ? -Math.abs(qtdPorFila + 1) : -qtdPorFila);

        for (let i = 0; i < this.seats.length; i++) {
          for (let j = 0; j < fila.length; j++) {
            if (fila[j].id == this.seats[i].id) {
              this.seats[i].vendido = true;
            }
          }

          for (let j = 0; j < fila2.length; j++) {
            if (fila2[j].id == this.seats[i].id) {
              this.seats[i].vendido = true;
            }
          }

          for (let j = 0; j < fila3.length; j++) {
            if (fila3[j].id == this.seats[i].id) {
              this.seats[i].vendido = true;
            }
          }

          for (let j = 0; j < fila4.length; j++) {
            if (fila4[j].id == this.seats[i].id) {
              this.seats[i].vendido = true;
            }
          }
        }
      }
    },
  },
  // watch: {
  //   immediate: true,
  //   buses: function(newVal, oldVal) {
  //     // watch it
  //     console.log("Prop changed: ", newVal, " | was: ", oldVal);
  //   }
  // }
};
</script>
