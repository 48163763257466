<script>
import Helpers from "@/mixins";
import {
  Title,
  TripDetailsContainer,
  TripDetailsContent,
  Container,
  TimeLabel,
  DetailsIconsContainer,
  DetailsLine,
  TimeTravelLabel,
  TimeTravelLabelContainer,
  DirectionContainer,
  DirectionTravelLabel,
  LineLabel,
  LineContainer,
  ActionsItem,
  ActionsContainer,
  ActionsItemText,
  ActionsSubContainer,
  ActionsSubItem,
  Content,
} from "./styled";
export default {
  components: {
    Title,
    TripDetailsContainer,
    TripDetailsContent,
    TimeLabel,
    Container,
    DetailsIconsContainer,
    DetailsLine,
    TimeTravelLabel,
    TimeTravelLabelContainer,
    DirectionTravelLabel,
    DirectionContainer,
    LineLabel,
    LineContainer,
    ActionsItem,
    ActionsContainer,
    ActionsItemText,
    ActionsSubContainer,
    ActionsSubItem,
    Content,
  },
  mixins: [Helpers],
  props: ["data", "tickets", "notBounds", "selectedTrips"],
  mounted() {
    this.Helpers;
  },
  methods: {
    createChildrenTicket() {
      this.$emit("criarPassagemCrianca");
    },
    createStandingTicket(payload) {
      this.$emit("criarPassagemEmPe", payload);
    },
  },
};
</script>
