<template>
  <v-dialog v-model="showModal" persistent max-width="290">
    <v-card>
      <v-card-title class="text-h5">
        Nenhum caixa aberto para hoje!
      </v-card-title>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="blue darken-1"
          block
          dark
          depressed
          @click="
            [
              $router.push('/pdv/financeiro/caixa-bilheteiro'),
              (showModal = false),
            ]
          "
        >
          IR PARA O CAIXA
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  data: () => ({
    showModal: false,
  }),
  mounted() {
    this.checkCashboxOpen();
  },
  methods: {
    checkCashboxOpen() {
      this.$store.dispatch("activeLoader", true);
      this.$http
        .post(`agencias/check-cashbox-open`, {
          agency_id: this.$store.getters.getOpAgency,
          operator_id: this.$store.getters.getOperador,
        })
        .then((response) => {
          if (!response.data.status) {
            if (this.$store.getters.getOperadorLevel >= 5) {
              this.showModal = true;
            }
          }
        });
    },
  },
};
</script>
