import styled from "vue-styled-components";
import { defaultTheme } from "../../../../shared/theme";
import { themeResolver } from "../../../../utils";

const themeColor = defaultTheme.themes[themeResolver()];

export const TravelCardContainer = styled.div`
  filter: drop-shadow(-3px 3px 8px rgba(50, 50, 0, 0.5));
  display: flex;
  border-radius: 16px;
`;

export const LeftTravelCard = styled.div`
  width: 90%;
  height: 170px;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
  position: relative;
  cursor: pointer;
  background-color: #fff;
  clip-path: polygon(
    6% 0,
    91% 0,
    100% 0,
    100% 89%,
    100% 100%,
    5% 100%,
    0 90%,
    0 9%
  );
`;

export const LeftTravelCardListItem = styled.li`
  list-style: none;
  color: ${themeColor.secondary};
`;

export const LeftTravelCardContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const LeftTravelCardContent = styled.div`
  padding: 6px 10px;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const LeftTravelCardFooter = styled.div`
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: absolute;
  bottom: 0;

  b {
    color: ${themeColor.primary};
  }
`;

export const LeftTravelCardColorText = styled.p`
  color: ${themeColor.primary};
`;

// RIGHT PART
export const RightTravelCard = styled.div`
  display: flex;
  justify-content: center;
  width: 45%;
  height: 170px;
  border-radius: 16px;
  padding: 10px 0;
  background-color: ${themeColor.primary};
  border-right: 3px dashed ${themeColor.warning_darken};
  clip-path: polygon(
    0 0,
    86% 0,
    100% 10%,
    100% 89%,
    86% 100%,
    0 100%,
    0 77%,
    0 25%
  );
`;

export const RightTravelContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const RightTravelText = styled.div`
  font-weight: 400;
  margin: 10px 0 0 0;
  padding: 0 1px;
  text-align: center;
  color: ${themeColor.contrastText};
`;

export const RightTravelTimeText = styled.h2`
  font-size: 2rem;
  font-weight: 400;
  color: ${themeColor.warning};
`;
