<template>
  <div>
    <v-autocomplete
      v-model="ticket.cliente"
      label="Documento"
      :items="loadFilteredClientes(ticket.crianca)"
      item-value="id"
      item-text="documento"
      @change="[selectClient(ticket.cliente), checkTickets()]"
      :loading="loading"
      :search-input.sync="searchDocumento"
      hide-no-data
    />
    <v-autocomplete
      v-model="ticket.cliente"
      label="Cliente"
      :items="loadFilteredClientes(ticket.crianca)"
      item-value="id"
      item-text="nome"
      append-outer-icon="mdi-account-plus-outline"
      @click:append-outer="
        openNewClientModal(false, ticket.crianca ? 'C' : null)
      "
      @change="[selectClient(ticket.cliente), checkTickets()]"
      :loading="loading"
      :search-input.sync="searchNome"
      hide-no-data
    />

    <v-dialog v-model="showDialog" persistent width="600px">
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
        autocomplete="off"
        @submit.prevent
      >
        <v-card>
          <v-card-title
            class="primary white--text d-flex justify-space-between"
          >
            <span>Adicionar Data de Nascimento</span>
          </v-card-title>

          <v-card-text class="pt-7 px-7">
            <v-row dense class="px-1">
              <p>
                <strong>
                  Devido às novas regras da ANTT, a data de nascimento do
                  passageiro tornou-se um requisito obrigatório para a venda da
                  passagem.
                </strong>
              </p>
            </v-row>
            <v-row dense>
              <v-col cols="12">
                <span class="primary--text">Data de Nascimento*</span>
                <v-text-field
                  v-model="data_nascimento"
                  placeholder="Informe a data de nascimento"
                  class="mt-n1"
                  :rules="dataRules"
                  v-mask-date.br
                  required
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="d-flex justify-end px-5">
            <v-btn depressed text :disabled="!valid" @click="validate">
              Confirmar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: ["ticket"],
  data: () => ({
    clients: [],
    loading: false,
    searchDocumento: null,
    searchNome: null,
    showDialog: false,
    valid: true,
    data_nascimento: "",
    clientSelected: null,
    dataRules: [
      (v) => !!v || "Este campo é requerido",
      (v) =>
        /^\d{2}\/\d{2}\/\d{4}$/.test(v) ||
        "Insira uma data válida no formato DD/MM/AAAA!",
      (v) => {
        const [dia, mes, ano] = v.split("/").map(Number);
        const data = new Date(ano, mes - 1, dia);

        // Verifica se a data é válida
        if (
          data.getDate() !== dia ||
          data.getMonth() !== mes - 1 ||
          data.getFullYear() !== ano
        ) {
          return "Insira uma data válida!";
        }

        // Verifica se a data não é no futuro
        const hoje = new Date();
        if (data > hoje) {
          return "A data não pode ser no futuro!";
        }

        return true;
      },
    ],
    message: {
      snacshow: false,
      text: "",
      type: "",
      mode: "",
      timeout: 2000,
    },
  }),
  watch: {
    searchDocumento(val) {
      if (!val || val.length < 3) {
        this.clients = [];
        return;
      }

      this.loading = true;
      this.querySelections(val);
    },
    searchNome(val) {
      if (!val || val.length < 3) {
        this.clients = [];
        return;
      }

      this.loading = true;
      this.querySelections(val);
    },
  },
  methods: {
    loadFilteredClientes(crianca) {
      return crianca
        ? this.clients.filter((client) => client.tipo === "C")
        : this.clients.filter((client) => client.tipo !== "C");
    },
    selectClient(clientId) {
      this.clientSelected = this.clients.find((value) => value.id == clientId);

      if (!this.clientSelected.data_nascimento) {
        this.showDialog = true;
      } else {
        const [ano, mes, dia] = this.clientSelected.data_nascimento
          .split("-")
          .map(Number);
        const dataNascimento = new Date(ano, mes - 1, dia);

        if (
          dataNascimento.getDate() !== dia ||
          dataNascimento.getMonth() !== mes - 1 ||
          dataNascimento.getFullYear() !== ano
        ) {
          this.showDialog = true;
        }
      }

      this.$emit("selectClient", clientId);
    },
    checkTickets() {
      this.$emit("checkTickets");
    },
    openNewClientModal(show = false, type = null) {
      this.$emit("openNewClientModal", show, type);
    },
    querySelections(v) {
      clearTimeout(this._searchTimerId);
      this._searchTimerId = setTimeout(() => {
        this.$http
          .get(`/clientes/search/${v}`)
          .then((r) => {
            this.clients = r.data;
          })
          .finally(() => (this.loading = false));
      }, 500);
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.$store.dispatch("activeLoader", true);

        this.$http
          .post(`/clientes/update/${this.clientSelected.id}`, {
            data_nascimento: this.data_nascimento,
          })
          .then((r) => {
            this.$store.dispatch("activeLoader", false);
            if (r.data.success) {
              this.message.text = "Atualizado com Sucesso!";
              this.message.snacshow = true;
              this.message.type = "success";
              this.clientSelected = null;
              this.data_nascimento = "";
              this.showDialog = false;
            } else {
              this.$store.dispatch("activeLoader", false);
              this.message.text = "Falha ao atualizar";
              this.message.snacshow = true;
              this.message.type = "warning";
            }
          })
          .catch((e) => {
            console.log(e);
            this.$store.dispatch("activeLoader", false);
            this.message.text = "Falha ao atualizar";
            this.message.snacshow = true;
            this.message.type = "success";
          });
      } else {
        this.message.snacshow = true;
        this.message.type = "warning";
        this.message.text =
          "Atenção: Informa a data de nascimento para continuar.";
      }
    },
  },
};
</script>
