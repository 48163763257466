<script>
import { Container } from "./styled";
import Helpers from "@/mixins";

export default {
  mixins: [Helpers],
  components: {
    Container,
  },
  data: () => ({
    trechos: [],
    search: {
      origem: 0,
      destino: 0,
      data_inicial: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      data_final: null,
    },
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    dateFormatted: null,
    currentDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menuCalendarioInicial: false,
    menuCalendarioFinal: false,
  }),
  created() {
    this.Helpers;
    this.dateFormatted = this.formatDate(
      new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10)
    );
    this.loadLocations();
  },
  methods: {
    clear() {
      Object.assign(this.$data, this.$options.data.apply(this));
      this.Helpers;
      this.dateFormatted = this.formatDate(
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10)
      );
      this.loadLocations();
    },
    loadTravels(search) {
      if (!search.data_inicial) {
        this.search.data_inicial = new Date(
          Date.now() - new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .substr(0, 10);
      }

      if (
        this.search.data_final == this.search.data_inicial &&
        this.search.data_final
      ) {
        this.search.data_final = null;
        alert("Data de VOLTA deve ser diferente da data de IDA");
      } else {
        this.$emit("loadViagens", search);
      }
    },
    async loadLocations() {
      const { data } = await this.$http.get(`/pdv/localidades`);
      this.trechos = data;
      this.search.origem = this.$store.getters.getAgency.id_localidade;
    },
  },
  computed: {
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
  },
  watch: {
    date() {
      this.dateFormatted = this.formatDate(this.date);
    },
    "$store.state.agency": {
      handler() {
        this.search.origem = this.$store.getters.getAgency.id_localidade;
      },
    },
  },
};
</script>
