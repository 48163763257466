<script>
import Helpers from "@/mixins";

export default {
  props: ["showModal", "type"],
  mixins: [Helpers],
  data: () => ({
    loading: false,
    message: {
      snacshow: false,
      text: "",
      type: "",
      mode: "",
      timeout: 2000,
    },
    clientData: {
      nome: "",
      tipo_documento: "",
      documento: "",
      data_nascimento: "",
      sexo: "",
      estado_civil: "",
      telefone: "",
      email: "",
      profissao: "",
      instituicao: "",
      nome_pai: "",
      nome_mae: "",
      cep: "",
      logradouro: "",
      numero: "",
      bairro: "",
      cidade: "",
      uf: "",
      complemento: "",
      pais: "",
      tipo: "",
    },
    items: [
      { id: "1", descricao: "RG" },
      { id: "2", descricao: "TÍTULO DE ELEITOR" },
      { id: "3", descricao: "PASSAPORTE" },
      { id: "4", descricao: "CNH" },
      { id: "5", descricao: "OUTROS" },
    ],
    tiposVenda: [],
    valid: true,
    fieldRules: [(v) => !!v || "Este campo é requerido"],
    nameRules: [
      (v) => !!v || 'Este campo é requerido',
      (v) => (v.trim().split(' ').length >= 2) || 'Insira pelo menos o nome e o sobrenome'
    ],
    emailRules: [
      (v) => !!v || "E-mail é requerido",
      (v) => /.+@.+\..+/.test(v) || "Digite um e-mail válido!",
    ],
    mensagemIdade: "", // Adicionando a variável para armazenar a mensagem de idade
    dataRules: [
      (v) => !!v || "Este campo é requerido",
      (v) =>
        /^\d{2}\/\d{2}\/\d{4}$/.test(v) ||
        "Insira uma data válida no formato DD/MM/AAAA!",
      (v) => {
        const [dia, mes, ano] = v.split("/").map(Number);
        const data = new Date(ano, mes - 1, dia);

        // Verifica se a data é válida
        if (
          data.getDate() !== dia ||
          data.getMonth() !== mes - 1 ||
          data.getFullYear() !== ano
        ) {
          return "Insira uma data válida!";
        }

        // Verifica se a data não é no futuro
        const hoje = new Date();
        if (data > hoje) {
          return "A data não pode ser no futuro!";
        }

        return true;
      },
    ],
  }),
  created() {
    this.Helpers;
  },
  watch: {
    // Observa as alterações na data de nascimento para recalcular a validade
    'clientData.data_nascimento': 'verificarIdade'
  },
  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        this.salvar();
      } else {
        this.message.snacshow = true;
        this.message.type = "warning";
        this.message.text = "Atenção: um ou mais campos precisam ser preenchidos!";
      }
    },
    verificarIdade() {
      const newVal = this.clientData.data_nascimento;
      this.mensagemIdade = ""; // Remove qualquer mensagem existente

      // Verifica se o tamanho do valor é 10 (formato dd/MM/yyyy)
      if (newVal.length === 10) {
        const hoje = new Date();
        const partesData = newVal.split('/');
        const dia = parseInt(partesData[0], 10);
        const mes = parseInt(partesData[1], 10) - 1;
        const ano = parseInt(partesData[2], 10);
        const dataNascimento = new Date(ano, mes, dia);
        
        let idade = hoje.getFullYear() - dataNascimento.getFullYear();
        const mesAtual = hoje.getMonth();
        const diaAtual = hoje.getDate();
        
        if (mesAtual < mes || (mesAtual === mes && diaAtual < dia)) {
          idade--;
        }

        // Verifica se a idade é maior ou igual a 6 anos
        if (idade >= 6) {
          this.mensagemIdade = `A CRIANÇA JÁ TEM ${idade} ANOS E JÁ PAGA PASSAGEM.`;
          this.valid = false; // Desabilita o botão "Confirmar"
        } else {
          this.valid = true; // Habilita o botão "Confirmar"
        }
      }
    },
    cleanFields() {
      this.clientData = {
        nome: "",
        tipo_documento: "",
        documento: "",
        data_nascimento: "",
        sexo: "",
        estado_civil: "",
        telefone: "",
        email: "",
        profissao: "",
        instituicao: "",
        nome_pai: "",
        nome_mae: "",
        cep: "",
        logradouro: "",
        numero: "",
        bairro: "",
        cidade: "",
        uf: "",
        complemento: "",
        pais: "",
        tipo: "",
      };
      this.valid = true; // Reseta a validade ao limpar campos
    },
    async salvar() {
      const newClientData = {
        ...this.convertObjectKeysToUppercase(this.clientData),
      };
      newClientData.tipo = this.type;
      await this.executeRequest("post", "/clientes/create", newClientData);
      this.showClientModal();
      this.cleanFields();
    },
    showClientModal(type = null) {
      this.cleanFields();
      this.clientData.tipo = this.type;
      this.$emit("showClientModal", true, type);
    },
  },
};
</script>
